import React from 'react';
import axios from "axios";
import Medias from "./_partials/Medias";

class Gallery extends React.Component  {
  constructor() {
    super()
    this.state = {
      medias: []
    }
  }

    componentDidMount() {
      axios.get('https://api.maheboissel.fr/api/media')
          .then(res => {
            this.setState({ medias : res.data }, () => {
              //console.log('this.state media')
            })
          })
          .catch(error => {
            console.log(error)
          })
  }

  // functions

  render() {

      const medias = Object
          .keys(this.state.medias)
          .map(key => <Medias key={key} identifier={key} media={this.state.medias[key]}  />)
      ;

    return (
        <>
            <div className="blog-4">
                <div className="container">
                    <div className="space-top"></div>
                    <div className="row">
                        <div className="col-md-12 ml-auto mr-auto text-center">
                            <h2 className="title">Galerie</h2>
                        </div>
                    </div>
                    <div className="row">
                        {medias}
                    </div>
                </div>
            </div>
        </>
    )
  }
}

export default Gallery;
