import React from 'react';
// import {Container, Row, Col, Nav} from 'react-bootstrap';

class Footer extends React.Component {
    render() {
        return (
            <>
                <footer className="footer footer-black footer-white ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <nav className="footer-nav">
                                    <ul>
                                        <li><a href="/galerie">Galerie</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                        <li><a href="/mentions-legales">Mentions légales</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-md-6">
                                <div className="credits ml-auto">
                                  <div className="copyright">
                                      © 2023 Maheboissel.fr, made with <i className="fa fa-heart heart"></i>
                                      by <a href="https://www.edouardboissel.fr" target="_blank" rel="noreferrer">Edouard Boissel</a>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }

}

export default Footer;
