import React from 'react';

class Social extends React.Component {

    render() {
        return (
            <>
                <div className="sociallinks">
                    <nav className="socialnav">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/maheboissel/"
                                   className="btn btn-just-icon btn-facebook"
                                   target="_blank"
                                   rel="noreferrer">
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/maheboissel"
                                   className="btn btn-just-icon btn-twitter"
                                   target="_blank"
                                   rel="noreferrer">
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.pinterest.fr/maheboissel/"
                                   className="btn btn-just-icon btn-pinterest"
                                   target="_blank"
                                   rel="noreferrer">
                                    <i className="fa fa-pinterest" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/mahe_boissel/"
                                   className="btn btn-just-icon btn-instagram"
                                   target="_blank"
                                   rel="noreferrer">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </>
        );
    }

}

export default Social;
