import React from 'react';

class Legals extends React.Component  {

  render() {
    return (
        <div className="">
        Mentions legales
        </div>
    )
  }

}

export default Legals;
