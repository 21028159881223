
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/_partials/Header";
import Social from "./components/_partials/Social";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import Gallery from "./components/pages/Gallery";
import Category from "./components/pages/Category";
import Post from "./components/pages/Post";
import Legals from "./components/pages/Legals";
import Footer from "./components/_partials/Footer";

function App() {
  return (
      <>
          <Header />
          <Social />
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/contact" element={<Contact/>} />
                  <Route path="/galerie" element={<Gallery />} />
                  <Route path="/categorie/:id/*" element={<Category />} />
                  <Route path="/article/:id/*" element={<Post />} />
                  <Route path="/mentions-legales" element={<Legals />} />
              </Routes>
          </BrowserRouter>
          <Footer />
      </>
  );
}

export default App;
