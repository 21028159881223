import React from 'react';

class Contact extends React.Component  {

  render() {
    return (
        <div className="">
        Contact
        </div>
    )
  }

}

export default Contact;
