import React from 'react';

class Medias extends React.Component {

    render() {
        return (
            <>
                <div className="col-md-6">
                    <div className="card card-plain card-blog text-center">
                        <div className="card-image card-media">
                            <a href={`/article/${this.props.media.post.id}/${this.props.media.post.slug}`}>
                                <img src={ this.props.media.mediaUrl } className="img img-raised img-responsive" alt="media" />
                            </a>
                        </div>
                        <div className="card-body">
                            <h6 className="card-category text-default">{ this.props.media.post.category.title }</h6>
                            <h3 className="card-title">
                                <a href={`/article/${this.props.media.post.id}/${this.props.media.post.slug}`}>
                                    { this.props.media.post.title }
                                </a>
                            </h3>
                            <p className="card-description">
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default Medias;
