import React from 'react';

class Posts extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            colSize: 0
        };
    }

    componentDidMount() {
        // Calculate the colSize we want to apply
        this.setState({ colSize : (this.props.identifier % 3) });
        if ((this.props.identifier % 2) && ((this.props.identifier -1) % 3)) {
            this.setState({ colSize : 0 });
        }
    }

    render() {

        const cols = ["col-md-5", "col-md-7", "col-md-7"];

        return (
            <>
                <div className={ cols[this.state.colSize] }>
                    <div className="card posts picture picture_effect" data-background="image">
                        <img src={ this.props.post.pictureUrl } className="img-responsive" alt={ this.props.post.title } />
                        <div className="card-body">
                            <h6 className="card-category">{ this.props.post.category.title }</h6>
                            <a href={`/article/${this.props.post.id}/${this.props.post.slug}`}>
                                <h3 className="card-title">{ this.props.post.title }</h3>
                            </a>
                            <br />
                            <a href={`/article/${this.props.post.id}/${this.props.post.slug}`} className="btn btn-danger btn-round">
                                <i className="fa fa-book" aria-hidden="true"></i> Lire
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default Posts;
