import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import axios from "axios";

class Header extends React.Component {

    constructor() {
        super()
        this.state = {
            categories: []
        }
    }

    componentDidMount() {
        axios.get('https://api.maheboissel.fr/api/category')
            .then(res => {
                this.setState({ categories : res.data }, () => {
                    // console.log('this.state post')
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const menus = this.state.categories.map((category) =>
            <Nav.Item key={category.id} >
                <Nav.Link href={`/categorie/${category.id}/${category.slug}`}>
                    {category.title}
                </Nav.Link>
            </Nav.Item>
        );

        return (
            <>
                <Navbar expand="lg" variant="light" fixed="top">
                    <Container>
                        <div className="navbar-translate">
                            <Navbar.Brand href="/">
                                Mahé Boissel
                            </Navbar.Brand>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navigation" aria-controls="navigation-index" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span className="navbar-toggler-bar bar1"></span>
                                <span className="navbar-toggler-bar bar2"></span>
                                <span className="navbar-toggler-bar bar3"></span>
                            </button>
                        </div>
                        <Navbar.Collapse id="navigation-index">
                            <Nav className="ml-auto">
                                {menus}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div className="header-space"></div>
            </>
        );
    }

}

export default Header;
