import React from 'react';
import axios from "axios";
import {useParams} from "react-router-dom";
//import Post from "./_partials/Post";

class Post extends React.Component {

    constructor() {
        super()
        this.state = {
            post: []
        }
    }

    componentDidMount() {
        //console.log(this.props.match.params.id);
        axios.get('https://api.maheboissel.fr/api/post/show/' + this.props.match.params.id)
            .then(res => {
                this.setState({ post : res.data }, () => {
                    // console.log('this.state post')
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        const post = Object
            .keys(this.state.post)
            .map(key => <Post key={key} identifier={key} post={this.state.post[key]}  />)
        ;

        return (
            <>
                <div className="projects-1 posts">
                    <div className="container">
                        <div className="space-top"></div>
                        <div className="row">
                            <p><strong>{post.title}</strong></p>
                            <p>{post.slug}</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default withRouter(Post);

export function withRouter(Children){
    return(props)=>{

        const match  = {params: useParams()};
        return <Children {...props}  match = {match}/>
    }
}
