import React from 'react';
import axios from "axios";
import Posts from "./_partials/Posts";
//import { motion as m } from "framer-motion";

class Home extends React.Component {

    constructor() {
        super()
        this.state = {
            posts: [],
            next_page_url: '',
            prev_page_url: '',
            loading: 1,
        }
    }

    componentDidMount() {
        axios.get('https://api.maheboissel.fr/api/post/paginated/12')
            .then(res => {
                this.setState({
                    posts : res.data.data,
                    next_page_url : res.data.next_page_url,
                    prev_page_url : res.data.prev_page_url,
                    loading : 0
                }, () => {
                    // console.log('this.state posts')
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        const posts = Object
            .keys(this.state.posts)
            .map(key => <Posts key={key} identifier={key} post={this.state.posts[key]}  />)
        ;

        return (
            <>
                <div className="projects-1">
                    <div className="container">
                        <div className="space-top"></div>
                        <div className="row">
                            {posts}
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default Home;
