import React from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import Posts from "./_partials/Posts";

class Category extends React.Component {

    constructor() {
        super()
        this.state = {
            posts: [],
            categoryTitle: '',
            next_page_url: '',
            prev_page_url: '',
            loading: 1,
        }
    }

    componentDidMount() {
        //console.log(this.props.match.params.id);
        axios.get('https://api.maheboissel.fr/api/post/category/' + this.props.match.params.id + '/paginated/12')
            .then(res => {
                //console.log(res.data)
                this.setState({
                    posts : res.data.data,
                    categoryTitle : res.data.data[0].category.title,
                    next_page_url : res.data.next_page_url,
                    prev_page_url : res.data.prev_page_url,
                    loading : 0
                }, () => {
                    //console.log(this.state)
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        const posts = Object
            .keys(this.state.posts)
            .map(key => <Posts key={key} identifier={key} post={this.state.posts[key]}  />)
        ;

        //const categoryTitle = this.state.categoryTitle;

        return (
            <>
                <div className="projects-1">
                    <div className="container">
                        <div className="space-top"></div>
                        <div className="row">
                            <div className="col-md-12 ml-auto mr-auto text-center">
                                <h2 className="title">{ this.state.categoryTitle }</h2>
                            </div>
                        </div>
                        <div className="row">
                            {posts}
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default withRouter(Category);

export function withRouter(Children){
    return(props)=>{

        const match  = {params: useParams()};
        return <Children {...props}  match = {match}/>
    }
}
